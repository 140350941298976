/* I made these changes in nprogress.css */

#nprogress .bar {
  height: 3px;
  background: #8f1838;
}
#nprogress .spinner-icon {
  width: 25px;
  height: 25px;
  border: solid 3px transparent;
}
